import Core from './Core'

/**
 * Static import of Core
 */
class CoreUtil extends Core{

    constructor () {
        super()
    }

}
export default new CoreUtil()